<template>
  <div class="help_center">
    <div class="left_box">
      <div class="left_title">帮助中心</div>
      <div
        @click="selectTab = '1'"
        :class="{ left_tab: true, left_tab_select: selectTab == '1' }"
      >
        系统使用手册
      </div>
      <!-- <div
        @click="selectTab = '2'"
        :class="{ left_tab: true, left_tab_select: selectTab == '2' }"
      >
        系统操作视频
      </div> -->
    </div>
    <div class="right_box">
      <!-- 系统使用手册 -->
      <div v-if="selectTab == '1'">
        <el-card class="file_box">
          <div class="file_line">
            <span class="el-icon-folder-opened file_line_icon"></span>
            <a
              href="/file/社区运动会信息服务系统-使用手册.pdf"
              v-no-more-click
              target="_blank"
              >社区运动会信息服务系统-使用手册</a
            >
          </div>
        </el-card>
      </div>

      <!-- 系统操作视频 -->
      <div v-if="selectTab == '2'">
        <div class="video_list">
          <div class="video_item" v-for="(item, i) in videoList" :key="i">
            <div class="video_img_box">
              <img
                :src="item.contentImgUrl || $defaultImg"
                class="video_item_img"
              />
              <div class="video_mask">
                <img
                  src="@/assets/image/page/videoPlayer.png"
                  class="video_mask_img"
                  @click="videoPlayerClick(item)"
                />
              </div>
            </div>
            <div class="video_info">
              <div class="video_title">{{ item.contentTitle }}</div>
              <div class="video_time">
                {{ item.contentDatetime | timeFormat }}
              </div>
              <div class="video_content">
                {{ item.contentDescription }}
              </div>
            </div>
          </div>
        </div>
        <div class="page_box">
          <el-pagination
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
            :page-sizes="[10, 20, 30]"
            :page-size="pageSize"
            :current-page="pageNum"
            background
            layout="total, sizes, prev, pager, next, jumper"
            :total="total"
          ></el-pagination>
        </div>
      </div>
    </div>
    <div v-if="videoDialog">
      <el-dialog
        :title="showVideoInfo.contentTitle"
        :visible.sync="videoDialog"
      >
        <div class="video_box">
          <video
            :src="showVideoInfo.contentKeyword"
            :poster="
              showVideoInfo.contentImgUrl ? showVideoInfo.contentImgUrl : ''
            "
            :controls="true"
            height="400"
            width="600"
          ></video>
        </div>
        <div slot="footer" class="dialog-footer">
          <el-button type="primary" @click="videoDialog = false"
            >确 定</el-button
          >
        </div>
      </el-dialog>
    </div>
  </div>
</template>

<script>
import { getList } from "@/api/login";
import { img } from "@/utils/image";
export default {
  name: "helpCenter",
  data() {
    return {
      selectTab: "1",
      list1: [], //使用手册列表
      videoList: [], //使用视频列表
      pageNum: 1,
      pageSize: 10,
      total: 0,
      videoDialog: false,
      showVideoInfo: {},
    };
  },
  created() {
    this.getVideoList();
  },
  methods: {
    async getVideoList() {
      let res = await getList({
        pageNum: this.pageNum,
        pageSize: this.pageSize,
        categoryCode: "helpCenter",
      });
      if (res.code == "0") {
        this.videoList = res.data.cmsContents.dataList || [];
        this.total = res.data.cmsContents.total;
      } else {
        this.videoList = [];
        this.total = 0;
      }
    },
    handleSizeChange(size) {
      this.pageNum = 1;
      this.pageSize = size;
      this.getVideoList();
    },
    handleCurrentChange(page) {
      this.pageNum = page;
      this.getVideoList();
    },
    videoPlayerClick(video) {
      this.videoDialog = true;
      this.showVideoInfo = video;
    },
  },
};
</script>

<style scoped>
.help_center {
  display: flex;
  padding: 0.2rem 0.7rem;
  background-color: #f3f5f5;
  justify-content: space-between;
}
.left_box {
  width: 2.67rem;
  height: 5.62rem;
  padding-top: 0.2rem;
  background-color: #fff;
  box-shadow: 0px 2px 4px 0px rgba(62, 58, 57, 0.14);
  border-radius: 4px;
}
.left_title {
  color: #3e3a39;
  font-size: 16px;
  font-weight: 700;
  border-left: 5px solid #d80c24;
  margin-bottom: 15px;
  padding-left: 13px;
}
.left_tab {
  padding: 15px;
  text-align: center;
  cursor: pointer;
  color: #3e3a39;
  font-size: 16px;
  font-weight: 700;
}
.left_tab:hover {
  color: #d80c24;
}
.left_tab_select {
  color: #d80c24;
}

.right_box {
  width: 8.49rem;
  box-shadow: 0px 2px 4px 0px rgba(62, 58, 57, 0.14);
  border-radius: 4px;
  background-color: #fff;
  min-height: 5.62rem;
  padding: 0.2rem;
}
.file_box {
  width: 700px;
  margin-bottom: 10px;
}
.file_line {
  display: flex;
  align-items: center;
}
.file_line_icon {
  font-size: 18px;
  margin-right: 10px;
}

.video_list {
  min-height: 500px;
}
.video_item {
  display: flex;
  margin-bottom: 20px;
}
.video_img_box {
  margin-right: 20px;
  position: relative;
}
.video_mask {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 11;
  background-color: rgba(0, 0, 0, 0.3);
  display: flex;
  align-items: center;
  justify-content: center;
}
.video_mask_img {
  cursor: pointer;
  width: 0.44rem;
  height: 00.44rem;
}
.video_item_img {
  width: 2.98rem;
  height: 1.68rem;
}
.video_info {
  flex: 1;
  height: 100%;
  padding: 10px;
  color: #3e3a39;
  font-size: 14px;
}
.video_title {
  font-size: 18px;
  font-weight: 700;
}
.video_time {
  margin: 10px 0;
}
.video_content {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
  overflow: hidden;
  line-height: 26px;
}

.page_box {
  margin: 0.3rem 0;
  display: flex;
  justify-content: center;
}
.page_box
  /deep/
  .el-pagination.is-background
  .el-pager
  li:not(.disabled).active {
  background-color: #d80c24;
}

.video_box {
  display: flex;
  align-items: center;
  justify-content: center;
}
</style>
